import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ChartBase from './ChartBase';
export const _frontmatter = {
  "title": "공론조사와 숙의민주주의의 가능성",
  "slug": "data6",
  "date": "2022-03-07T15:00:00.000Z",
  "thumb": "../images/thumbs/thumb06.png",
  "featuredImg": "../images/featured/featured06.png",
  "summary": "한국인은 이러한 공론조사에 대해 어떻게 생각할까? 공론조사 확대 도입과 공론조사 기구 상설화에 대한 의견을 물었다.",
  "tags": ["정책", "공론조사", "숙의형조사"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`2017년 정부가 신고리 원전 5·6호기 존폐를 공론조사에 붙임으로써 공론조사 방식이 알려졌다. 공론조사는 과학적 표본 추출에 의한 여론조사와 응답자들의 학습과 토론을 결합한 방식이다. 가장 먼저 특정 주제로 여론조사를 한다. 그 후 1차 여론조사 응답자 중에서 성 지역·연령 등에 대표성을 지닌 토론 참여자를 선정한다. 이들은 한자리에 모여 전문가들의 강연을 듣는 등 정보를 접하고 상호 토론을 거친다. 이 단계를 거친 후에 1차 여론조사와 같은 질문으로 다시 여론조사를 한다. 첫 여론 조사와 마지막 여론조사의 차이는 공론화 과정에서의 의견 변화가 된다.`}<br parentName="p"></br>{`
`}{`한국인은 이러한 공론조사에 대해 어떻게 생각할까? 정부의 일반적인 정책 결정, 국회 표결 등 대의민주주주의 의사결정 방식과 국민 공론화를 통한 결정인 숙의민주주의를 선호하고 있을까? 공론조사 확대 도입과 공론조사 기구 상설화에 대한 의견을 물었다.`}</p>
    <h2>{`중요한 국가 의사결정에 공론조사가 필요한가?`}</h2>

    <ChartBase type={'bar'} data={props.data} columns={props.columns} mdxType="ChartBase"></ChartBase>
    <p>{`신고리 원전 공론조사 직후 이뤄진 이번 여론조사에서 공론조사 확대 의견이 높았는데, 이것은 공론화위원회 결론과 관계없이 공론조사 자체에 형성된 것으로 보인다.`}</p>
    <h3>{`공론조사 상설 기구 설립`}</h3>
    <p>{`<공론조사를 전담할 상설기구를 설립해 운영하는 데 공감하는가?>라는 질문에 72.2%가 공감한다고 답했으며, 24.5%는 비공감, 2.8%는 의견을 유보했다.`}<br parentName="p"></br>{`
`}{`공론조사를 전담해 추진할 상설 기구를 설립하자는 주장에 대한 공감 의견이 압도적으로 높았다.`}</p>
    <h3>{`공론조사 활성화 방안`}</h3>
    <p>{`신고리 원전 공론조사는 국가와 지방자치단체 의사결정과 여론 수렵에 대한 새로운 가능성을 보여주었다. 미국과 프랑스, 캐나다 등의 국가는 공로화 상설 기구를 운영하며 다양한 쟁점을 다루고 있다. 그런데 어떤 사안을 공론화하고 그 결과를 얼마나 반영하는지가 문제다. 공론화 상설 기구가 설치된 국가에서도 공론조사 결과를 정부가 100% 수용하도록 법적 의무를 지지는 않는다.`}</p>
    <h6>{`*<세계일보>와 공공의창이 기획하고 여론조사 전문기관 타임리서치가 수행했다. 2017년 10월 25일 전국 19세 이상 성인남녀 1,006명을 대상으로 했으며, 표본오차는 95% 신뢰수준에 ±3.1%다.`}</h6>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      